//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-768:_9704,_8480,_6536,_9086,_474,_2898,_8502,_9024;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-768')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-768', "_9704,_8480,_6536,_9086,_474,_2898,_8502,_9024");
        }
      }catch (ex) {
        console.error(ex);
      }